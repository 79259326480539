import { AiChatProjectMeta } from '@pixels/universal/model/apps/project-meta/ai-chat-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { DEV_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: AiChatProjectMeta.dev?.apiUrl ?? '',
  phase: Phase.dev,
  firebase: AiChatProjectMeta.dev?.firebaseConfig ?? {},
  talkPlusAppId: AiChatProjectMeta.dev?.talkPlusAppId ?? '',
  vapidKey: AiChatProjectMeta.dev?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: DEV_PROXY_URL
};
